/* eslint-disable  custom-rules/no-deep-imports */
import { I18nextProvider } from "react-i18next";

import {
  type LoaderFunctionArgs,
  type TypedResponse,
  json,
  redirect,
} from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";

import IndexScreen from "~/components/screens/index";
import i18n from "~/i18n";
import { login } from "~/shopify.server";

type IndexLoader = Promise<
  TypedResponse<{
    showForm: boolean;
  }>
>;

export const loader = async ({ request }: LoaderFunctionArgs): IndexLoader => {
  const url = new URL(request.url);

  if (url.searchParams.get("shop")) {
    throw redirect(`/app?${url.searchParams.toString()}`);
  }

  return json({ showForm: Boolean(login) });
};

export default function App(): JSX.Element {
  const { showForm } = useLoaderData<typeof loader>();
  return (
    <I18nextProvider i18n={i18n}>
      <IndexScreen showForm={showForm} />
    </I18nextProvider>
  );
}
