/* eslint-disable  custom-rules/no-deep-imports */
import { type FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "@remix-run/react";

import SSpinner from "~/components/elements/SSpinner";
import { useLocale } from "~/hooks/useLocale";
import publicFiles from "~/public";

import styles from "./styles.module.css";

interface IndexScreen {
  showForm: boolean;
}

const IndexScreen: FC<IndexScreen> = ({ showForm }) => {
  const loading = useLocale();
  const { t } = useTranslation();

  const renderFeature = useCallback(
    (first_text: string, second_text: string, image: string) => (
      <div className={styles.featureContainer}>
        <div className={styles.featureContent}>
          <img src={image} alt="feature-iamge" draggable={false} />
          <p className={styles.description}>{first_text}</p>
          <p className={styles.description}>{second_text}</p>
        </div>
        <div className={styles.bubbleContainer}>
          <div className={styles.bubble} />
        </div>
      </div>
    ),
    []
  );

  if (loading) return <SSpinner />;

  return (
    <div className={styles.index}>
      <div className={styles.content}>
        <div className={styles.mainBubbleContainer}>
          <div className={styles.bubble} />
        </div>
        <div className={styles.headerContainer}>
          <h1 className={styles.heading}>{t("screens.index.header")}</h1>
          <div className={styles.descriptionContainer}>
            <p className={styles.description}>
              {t("screens.index.description")}
            </p>
          </div>
        </div>
        {showForm && (
          <Form className={styles.form} method="post" action="/auth/login">
            <div className={styles.label}>
              <span className={styles.text}>
                {t("screens.index.form.label")}
              </span>
              <input
                className={styles.input}
                type="text"
                name="shop"
                placeholder={t("screens.index.form.placeholder")}
              />
              <span className={styles.text}>
                {t("screens.index.form.help")}
              </span>
            </div>
            <button className={styles.button} type="submit">
              <p className={styles.buttonTitle}>
                {t("screens.index.form.button")}
              </p>
            </button>
          </Form>
        )}
      </div>
      <div className={styles.featuresContainer}>
        {renderFeature(
          t("screens.index.features.game.description.first"),
          t("screens.index.features.game.description.second"),
          publicFiles.assets.images.index.GameControllerWEBP
        )}
        <div className={styles.rocketContainer}>
          {renderFeature(
            t("screens.index.features.rocket.description.first"),
            t("screens.index.features.rocket.description.second"),
            publicFiles.assets.images.index.RocketWEBP
          )}
        </div>
        {renderFeature(
          t("screens.index.features.sale.description.first"),
          t("screens.index.features.sale.description.second"),
          publicFiles.assets.images.index.SaleStickerWEBP
        )}
      </div>
    </div>
  );
};

export default IndexScreen;
